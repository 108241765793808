<template>
  <div :class="$style.periodWeekdays">
    <button
      v-for="(weekday, i) in weekdays"
      :key="weekday"
      :class="[$style.weekday, { [$style.selected]: value.includes(i), [$style.error]: error }]"
      @click="clickHandler(i)"
    >
      <p>{{ weekday }}</p>
    </button>
  </div>
</template>

<script>
const weekdays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

export default {
  name: 'PeriodWeekdays',
  model: {
    prop: 'value',
    event: 'set'
  },
  props: {
    value: { type: Array, default: () => [] },
    error: { type: Boolean, default: false }
  },
  data() {
    return {
      weekdays
    }
  },
  methods: {
    clickHandler(i) {
      if (this.value.includes(i)) {
        this.$emit(
          'set',
          this.value.filter((index) => index !== i)
        )
      } else {
        const newValue = [...new Set([...this.value, i])]
        this.$emit('set', newValue)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.periodWeekdays {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.weekday {
  width: 2rem;
  height: 2rem;
  background-color: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  p {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: $gray-squirrel;
  }
}
.weekday.selected {
  background-color: #62bb58;

  p {
    color: $white;
  }
}
.weekday.error {
  border-color: $error;
}
</style>
