var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[_vm.$style.checkbox__wrapper, { [_vm.$style.checkbox__wrapper_disabled]: _vm.disabled }]},[_c('input',{class:_vm.$style.checkbox__checkbox,attrs:{"type":_vm.radio ? 'radio' : 'checkbox'},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('div',{staticClass:"checkbox-wrapper",class:[
      _vm.$style.checkbox__label,
      _vm.$style[_vm.type],
      { [_vm.$style.checkbox__label_disabled]: _vm.disabled },
      { [_vm.$style.checkbox__label_error]: _vm.error },
      { [_vm.$style.checkbox__label_noborder]: _vm.noborder }
    ]},[_c('svg',{class:_vm.$style.icon,attrs:{"width":"14","height":"10","viewBox":"0 0 14 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.25479 9.0712C5.12352 9.07147 4.99349 9.04581 4.87217 8.9957C4.75084 8.94559 4.64061 8.87202 4.54779 8.7792L0.305787 4.5362C0.210214 4.44402 0.133955 4.33373 0.0814616 4.21177C0.0289682 4.0898 0.00129084 3.9586 4.40849e-05 3.82582C-0.00120267 3.69305 0.0240064 3.56135 0.0742004 3.43842C0.124394 3.31549 0.198568 3.20379 0.292393 3.10983C0.386219 3.01587 0.497817 2.94154 0.620676 2.89117C0.743536 2.8408 0.875196 2.81541 1.00797 2.81647C1.14075 2.81752 1.27199 2.84502 1.39403 2.89734C1.51607 2.94966 1.62647 3.02576 1.71879 3.1212L5.25379 6.6562L11.6188 0.293203C11.8063 0.105562 12.0607 9.38308e-05 12.3259 6.25777e-08C12.5912 -9.37056e-05 12.8456 0.105195 13.0333 0.292703C13.2209 0.480211 13.3264 0.734579 13.3265 0.999849C13.3266 1.26512 13.2213 1.51956 13.0338 1.7072L5.96179 8.7792C5.86897 8.87202 5.75873 8.94559 5.6374 8.9957C5.51608 9.04581 5.38605 9.07147 5.25479 9.0712Z","fill":_vm.iconColor}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }